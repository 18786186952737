<template>
  <div class="agency">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__agencyManagement") }}</h2>
      <div
        class="d-flex align-items-center justify-content-between mb-3 flex-wrap"
      >
        <div class="d-flex align-items-center me-2 mb-2">
          <div class="device-report-select">
            <select v-model="searchAgent" class="form-select rounded-pill me-2">
              <option :value="null">{{ $t("__all") + $t("__agent") }}</option>
              <option
                v-for="item in agents"
                :value="item.id"
                :key="'agent' + item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <form @submit.prevent="searchAgency" class="me-auto me-2 mb-2">
          <div class="input-group rounded-pill">
            <input
              v-model="search"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchAgencyName')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="submit"
              class="btn btn-link px-2 text-dark"
              id="search"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t("__clearSearch") }}
            </button>
          </div>
        </form>
        <button
          @click="showModal()"
          class="mb-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm"
        >
          <i class="bi bi-plus"></i>
        </button>
      </div>
      <div class="card">
        <div v-if="items && agents" class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(locale)="data">
              {{ $t("__" + data.item.locale) }}
            </template>
            <template v-slot:cell(status)="data">
              <div
                class="status-switch"
                v-if="
                  isSystemManage ? data.item.manager_id === userInfo.id : true
                "
              >
                <input
                  type="checkbox"
                  :id="'switch-' + data.index"
                  v-model="data.item.status"
                  @click="changeStatusAlert(data.item.id)"
                />
                <label :for="'switch-' + data.index"></label>
              </div>
            </template>
            <template v-slot:cell(manager_id)="data">
              <div
                v-if="isSystemManage && data.item.manager_id !== userInfo.id"
              >
                {{
                  data.item.manager_id
                    ? data.item.manager.name +
                      " / " +
                      data.item.manager.username
                    : "Humetrics / Admin"
                }}
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div
                class="d-flex"
                v-if="
                  isSystemManage ? data.item.manager_id === userInfo.id : true
                "
              >
                <button
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
                <button
                  v-if="!data.item.status && !data.item.devices_count"
                  type="button"
                  class="btn btn-link px-1 text-danger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__delete')"
                  @click="deleteAgencyAlert(data.item.id)"
                >
                  <i class="bi bi-trash fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{
            $t("__paginationInfo", {
              startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
              entNub:
                currentPage == 1
                  ? totalRows > perPage
                    ? perPage
                    : totalRows
                  : perPage * (currentPage - 1) + perPage > totalRows
                  ? totalRows
                  : perPage * (currentPage - 1) + perPage,
              sum: totalRows,
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ isEdit ? $t("__editAgency") : $t("__addAgency") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="codename" class="form-label fw-medium required">{{
                  $t("__agencyCodename")
                }}</label>
                <input
                  v-model="modalData.codename"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.codename,
                  }"
                  id="codename"
                  required
                  :readonly="isEdit"
                />
                <div
                  class="invalid-feedback"
                  v-if="validate && validate.msg.codename"
                >
                  <div
                    v-for="(msg, index) in validate.msg.codename"
                    :key="index"
                  >
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="name" class="form-label fw-medium required">{{
                  $t("__agencyName")
                }}</label>
                <input
                  v-model="modalData.name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.name,
                  }"
                  id="name"
                  required
                />
                <div
                  class="invalid-feedback"
                  v-if="validate && validate.msg.name"
                >
                  <div v-for="(msg, index) in validate.msg.name" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="name" class="form-label fw-medium required">{{
                  $t("__language")
                }}</label>
                <select class="form-select" v-model="modalData.locale">
                  <option :value="null">
                    {{ $t("__openThisSelectMenu") }}
                  </option>
                  <option
                    v-for="item in language"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium required">{{
                  $t("__timezone")
                }}</label>
                <v-select
                  :placeholder="
                    $t('__openThisSelectMenu') + ' ( ' + $t('__search') + ' )'
                  "
                  v-model="modalData.timezone"
                  label="name"
                  :options="timezones"
                  :reduce="(timezone) => timezone.name"
                >
                  <template v-slot:option="option">
                    {{ option.name + " ： " + option.utc }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="changeStatusModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="changeStatusModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__changeStatus") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">
              {{ $t("__changeStatusNote", { Agency: modalData.name }) }}
            </p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="changeStatus"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="deleteModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__deleteAgency") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">
              {{ $t("__deleteAgencyNote", { Agency: modalData.name }) }}
            </p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="deleteAgency"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from "bootstrap";
import i18n from "@/lang/lang.js";
import { agency } from "@/http/api/agency.js";
import { agent } from "@/http/api/agent.js";
import { mapState, mapMutations } from "vuex";
import moment from "moment-timezone";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "Agency",
  components: { vSelect },
  data() {
    return {
      agents: null,
      agency: null,
      filter: null,
      filterOn: ["name"],
      search: null,
      searchAgent: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      editModalId: null,
      modalData: {
        name: null,
        codename: null,
        status: null,
        locale: null,
        timezone: null,
      },
      validate: null,
    };
  },
  computed: {
    ...mapState(["token", "userInfo", "isSystemManage", "language"]),
    fields() {
      return [
        {
          key: "name",
          label: i18n.t("__agencyName"),
          class: "align-middle",
          sortable: true,
        },
        {
          key: "codename",
          label: i18n.t("__agencyCodename"),
          class: "align-middle",
          sortable: true,
        },
        {
          key: this.isSystemManage ? "manager_id" : null,
          label: i18n.t("__agentName") + " / " + i18n.t("__account"),
          class: "align-middle",
          sortable: true,
        },
        {
          key: "locale",
          label: i18n.t("__language"),
          class: "align-middle text-center",
          sortable: true,
        },
        {
          key: "timezone",
          label: i18n.t("__timezone"),
          class: "align-middle text-center",
          sortable: true,
        },
        {
          key: "users_count",
          label: i18n.t("__usersCount"),
          class: "align-middle text-center",
          sortable: true,
        },
        {
          key: "devices_count",
          label: i18n.t("__deviceCount"),
          class: "align-middle text-center",
          sortable: true,
        },
        {
          key: "status",
          label: i18n.t("__status"),
          class: "align-middle text-center",
          sortable: false,
        },
        {
          key: "status",
          label: i18n.t("__status"),
          class: "align-middle text-center",
          sortable: false,
        },
        {
          key: "active",
          label: i18n.t("__active"),
          tdClass: "align-middle",
          class: "align-middle",
          sortable: false,
        },
      ];
    },
    items() {
      const vm = this;
      let agencyFilter = vm.agency;
      if (vm.searchAgent) {
        agencyFilter = agencyFilter.filter(
          (item) => vm.searchAgent === item.manager_id
        );
      }
      return agencyFilter;
    },
    timezones() {
      const timezones = [];
      moment.tz.names().forEach(function (timezone) {
        timezones.push({
          name: timezone,
          utc: moment.tz(timezone).format("Z"),
        });
      });
      return timezones;
    },
  },
  watch: {
    items(data) {
      const vm = this;
      if (data) {
        vm.initBootstrapTooltip();
        vm.totalRows = data.length;
        vm.currentPage = 1;
      }
    },
    modalData: {
      handler: function () {
        this.validate = null;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["Loading", "Loaded", "AlertsInfo", "Alerted"]),
    getAgent() {
      const vm = this;
      agent.get("", vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === "success") {
          vm.agents = res.data.data;
        }
      });
    },
    getAgency() {
      const vm = this;
      vm.Loading();
      agency
        .get("", vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === "success") {
            vm.agency = res.data.data;
          } else {
            vm.AlertsInfo({
              state: "error",
              title: i18n.t("__error"),
              info: res.data.errMsg.toString(),
            });
            vm.Alerted();
          }
          vm.Loaded();
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: "error",
            title: i18n.t("__error"),
            info: err,
          });
          vm.Alerted();
          vm.Loaded();
        });
    },
    searchAgency() {
      this.filter = this.search;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearSearch() {
      this.filter = null;
      this.search = null;
    },
    initBootstrapTooltip() {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
          });
        document
          .querySelectorAll('[data-bs-toggle="tooltip"]')
          .forEach((ele) => {
            ele.addEventListener("shown.bs.tooltip", function () {
              setTimeout(() => {
                Tooltip.getInstance(ele).hide();
              }, 600);
            });
          });
      });
    },
    hideTooltip() {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide();
        });
      }
    },
    showModal(id) {
      const vm = this;
      vm.editModalId = id;
      if (id) {
        vm.isEdit = true;
        vm.items.forEach((item) => {
          if (item.id === id) {
            vm.modalData.name = item.name;
            vm.modalData.codename = item.codename;
            vm.modalData.locale = item.locale;
            vm.modalData.timezone = item.timezone;
          }
        });
      } else {
        vm.isEdit = false;
        vm.modalData.name = null;
        vm.modalData.codename = null;
        vm.modalData.locale = null;
        vm.modalData.timezone = null;
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal);
      vm.$nextTick(function () {
        vm.bootstrap_modal.show();
      });
    },
    submitForm() {
      const vm = this;
      vm.Loading();
      if (vm.editModalId) {
        agency
          .update(
            vm.editModalId,
            {
              name: vm.modalData.name,
              codename: vm.modalData.codename,
              locale: vm.modalData.locale,
              timezone: vm.modalData.timezone,
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === "success") {
              vm.getAgency();
              vm.AlertsInfo({
                state: "success",
                title: i18n.t("__success"),
                info: i18n.t("__editSuccess"),
              });
              vm.Alerted();
              vm.bootstrap_modal.hide();
            } else {
              vm.AlertsInfo({
                state: "error",
                title: i18n.t("__error"),
                info: res.data.errMsg.toString(),
              });
              vm.Alerted();
              vm.validate = res.data.validate;
            }
            vm.Loaded();
          });
      } else {
        agency
          .create(
            {
              name: vm.modalData.name,
              codename: vm.modalData.codename,
              locale: vm.modalData.locale,
              timezone: vm.modalData.timezone,
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === "success") {
              vm.getAgency();
              vm.AlertsInfo({
                state: "success",
                title: i18n.t("__success"),
                info: i18n.t("__createSuccess"),
              });
              vm.Alerted();
              vm.bootstrap_modal.hide();
            } else {
              vm.AlertsInfo({
                state: "error",
                title: i18n.t("__error"),
                info: res.data.errMsg.toString(),
              });
              vm.Alerted();
              vm.validate = res.data.validate;
            }
            vm.Loaded();
          });
      }
    },
    changeStatusAlert(id) {
      const vm = this;
      vm.editModalId = id;
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.name = item.name;
          vm.modalData.status = !item.status;
        }
      });
      vm.bootstrap_modal = new Modal(vm.$refs.changeStatusModal);
      vm.bootstrap_modal.show();
      vm.$refs.changeStatusModal.addEventListener("hidden.bs.modal", () => {
        vm.getAgency();
      });
    },
    changeStatus() {
      const vm = this;
      vm.Loading();
      agency
        .changeStatus(
          vm.editModalId,
          {
            status: vm.modalData.status,
          },
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === "success") {
            vm.AlertsInfo({
              state: "success",
              title: i18n.t("__success"),
              info: i18n.t("__changeStatus") + i18n.t("__success"),
            });
          } else {
            vm.AlertsInfo({
              state: "error",
              title: i18n.t("__error"),
              info: res.data.errMsg.toString(),
            });
          }
          vm.Alerted();
          vm.bootstrap_modal.hide();
          vm.Loaded();
        });
    },
    getAgentName() {
      this.items.forEach((item, index) => {
        this.agents.forEach((agent) => {
          if (agent.id === item.id) {
            this.$set(this.items[index], "agent", agent);
          }
        });
      });
    },
    deleteAgencyAlert(id) {
      const vm = this;
      vm.editModalId = id;
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.name = item.name;
        }
      });
      vm.bootstrap_modal = new Modal(vm.$refs.deleteModal);
      vm.bootstrap_modal.show();
      vm.$refs.changeStatusModal.addEventListener("hidden.bs.modal", () => {
        vm.getAgency();
      });
    },
    deleteAgency() {
      const vm = this;
      agency.delete(vm.editModalId, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === "success") {
          vm.AlertsInfo({
            state: "success",
            title: i18n.t("__success"),
            info: i18n.t("__deleteAgency") + i18n.t("__success"),
          });
          vm.getAgency();
        } else {
          vm.AlertsInfo({
            state: "error",
            title: i18n.t("__error"),
            info: res.data.errMsg.toString(),
          });
        }
        vm.Alerted();
        vm.Loaded();
        vm.bootstrap_modal.hide();
      });
    },
  },
  created() {
    this.getAgency();
    this.getAgent();
  },
  mounted() {
    this.$watch(
      (vm) => [vm.agents, vm.items],
      (val) => {
        if (this.agents && this.items) this.getAgentName();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
};
</script>

<style  lang="scss">
</style>
