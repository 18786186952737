import { axiosConfig } from '../api/axios-config.js'

// 代理商
export const agent = {
  // 取得清單
  get: (id, token) => axiosConfig.get(`/api/agent/${id || ''}`, token),

  //  創建
  create: (data, token) => axiosConfig.post('/api/agent', data, token),

  // 更新
  update: (id, data, token) => axiosConfig.patch(`/api/agent/${id}`, data, token),

  // 重置密碼
  resetPassword: (id, token) => axiosConfig.put(`/api/agent/${id}/password`, '', token),

  // 更改狀態
  changeStatus: (id, data, token) => axiosConfig.put(`/api/agent/${id}`, data, token)
}
