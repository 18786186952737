import {axiosConfig} from '../api/axios-config.js'

// 機構
export const agency = {
  // 取得清單
  get: (id, token) => axiosConfig.get(`/api/agency/${id || ''}`, token),

  //  創建
  create: (data, token) => axiosConfig.post('/api/agency', data, token),

  // 更新
  update: (id, data, token) => axiosConfig.patch(`/api/agency/${id}`, data, token),

  // 更改狀態
  changeStatus: (id, data, token) => axiosConfig.put(`/api/agency/${id}`, data, token),

  // 刪除
  delete: (id, token) => axiosConfig.delete(`/api/agency/${id}`, token),

  getResidents: (id, token) => axiosConfig.get(`/api/agency/residents/${id}`, token),
}
